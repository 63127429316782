h1 {
    color: rgb(170, 170, 170);
    margin-top: 1vh;
    margin-bottom: 1vh;
  }

  .Container-box {
    max-width: 100%;
  }

.Container-header {
  height: 10%;
  padding-left: 1vw;
  padding-right: 1vw;
  display: flex;
  flex-direction: row;
  justify-content:space-between;
}

.Container-content {
  width: 100%;
  display:flex; 
  margin:0;
  padding: 0;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

.Container-footer {
  height: 10%;
  padding-left: 0.3em;
  padding-right: 0.3em;
  margin-left: 0.7em;
  margin-right: 0.7em;
  margin-bottom: 0.3em;
  display: flex;
  flex-direction: row;
  justify-content:space-around;
  border: 2px solid rgba(236, 103, 14, 1.5);  
  border-radius: 1em;
}

.Store-data {
  /* display:flex; */
  height: 100%;
  margin:0;
  padding: 5%;
  flex-direction: column;
  align-content: center;
  justify-content:space-evenly
}

.Store-data-text {
  margin: 0.2em;
  padding: 0;
  font-size: 1.5rem;
  text-align: justify;
}


.Gauge-icon{
  width: 80%;
  margin: auto;
}

.Info-quantidade,
.Container-Nome{
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Nome-filial {
  color: rgb(170, 170, 170);
  margin: 0;
}


.Footer-Icon,
.Header-icon {
  width: 1.5em; 
  height: 1.5em;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
}

.vl {
  border-left: 3px solid rgba(236, 103, 14, 1.5);
  height: 1em;
  margin-top: auto;
  margin-bottom: auto;
}

.Status-carga-icon {
  margin: 0;
}

.Modal {
  margin: auto;
  padding: 1.5em;
  background-color: rgba(255, 255, 255, 0.90);
  border: 4px solid rgba(236, 103, 14, 1.5);
  border-radius: 3vw;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  min-width: 50%;
  min-height: 30%;
  font-size: 1.3rem;
  align-items: center;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.50);
  display: flex;
  padding: 3%;
}