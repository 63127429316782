.App {
  text-align: center;
}

.App-logo {
  margin-top: 5vh;
  margin-bottom: 3vh;
  width: 75%;
  max-width: 500px;
  pointer-events: none;
}

h1 {
  color: rgb(170, 170, 170);
  margin-top: 1vh;
  margin-bottom: 1vh;
}

h2 {
  color: rgb(170, 170, 170);
}

h3 {
  color: rgb(170, 170, 170);
  margin: 0;
  padding-top: 0;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


/* Small devices such as large phones (640px and up) */
@media only screen and (min-width: 40em) {
  .grid-container {
    grid-template-columns: auto;
  }
}

/* Medium devices such as tablets (768px and up) */
@media only screen and (min-width: 48em) {
  .grid-container {
    grid-template-columns: auto auto;
  }
}


/* Large devices such as laptops (1024px and up) */
@media only screen and (min-width: 64em) {  
  .grid-container {
    grid-template-columns: auto auto auto;
  }
}

/* Largest devices such as desktops (1280px and up) */
@media only screen and (min-width: 80em) {
  .grid-container {
    grid-template-columns: auto auto auto auto auto;
  }
}

.grid-container {
  margin: auto;
  display: grid;
  width: 90%;
  padding: 0.5vw;
  justify-content:baseline;
  row-gap: 2vh;
  column-gap: 2vh;
}

.grid-item {
  margin: auto;
  border: 2px solid rgba(255, 255, 255, 1.5);  
  border-radius: 1em;
  font-size: 30px;
  text-align: center;
  width: 100%;
  max-width: 100%;
  /* border-radius: 3vw; */
}


.Container-setor {
  border-radius: 3vw;
  width: 93%;
  margin: 5px;
  padding: 3px;
}

.Acougue {
  border: 2px solid rgba(255, 0, 0, 2);
}

.Frios {
  border: 2px solid rgba(251, 255, 0, 2);
}